import { POOL_COMMENTS, PoolCommentsAction } from 'app/poolComments/actions/PoolComments';
import { get, isNull } from 'lodash';
import { toast } from 'react-toastify';
import { combineEpics, ofType } from 'redux-observable';
import {
  catchError,
  filter,
  ignoreElements,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import { PoolsActions } from '../../pools/actions/PoolsActions';
import { routingActionTypes } from '../../routing/actions/RoutingActions';

export const enteredPoolCommentsEpic = (action$, state$) => action$.pipe(
  ofType(routingActionTypes.ENTERED_POOL_COMMENTS_PAGE),
  map(() => get(state$.value, 'pools.currentPool', null)),
  map((currentPool) => [
    PoolsActions.fetchCollectionRequest(),
    PoolCommentsAction.action(POOL_COMMENTS.SET_POOL, currentPool || 1),
  ]),
);

export const addCommentPoolEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(PoolCommentsAction.request(POOL_COMMENTS.ADD_ITEM).type),
  mergeMap((action) => Services.poolComments.addComment(action.payload).pipe(
    map((addedComment) => [
      PoolCommentsAction.success(POOL_COMMENTS.ADD_ITEM, addedComment),
    ]),
    catchError(() => [
      PoolCommentsAction.failure(POOL_COMMENTS.ADD_ITEM),
    ]),
  )),
);

export const addCommentPoolSuccessEpic = (action$) => action$.pipe(
  ofType(
    PoolCommentsAction.success(POOL_COMMENTS.ADD_ITEM).type,
    PoolCommentsAction.success(POOL_COMMENTS.ADD_COMMENT).type,
  ),
  tap(() => toast.success('Created successfully')),
  ignoreElements(),
);

export const addCommentPoolFailureEpic = (action$) => action$.pipe(
  ofType(
    PoolCommentsAction.failure(POOL_COMMENTS.ADD_ITEM).type,
    PoolCommentsAction.failure(POOL_COMMENTS.ADD_COMMENT).type,
  ),
  tap(() => toast.error('Failure while creating')),
  ignoreElements(),
);

export const updateCommentPoolEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(PoolCommentsAction.request(POOL_COMMENTS.UPDATE_ITEM).type),
  mergeMap((action) => Services.poolComments.updateComment(action.payload).pipe(
    map((updatedComment) => [
      PoolCommentsAction.success(POOL_COMMENTS.UPDATE_ITEM, updatedComment),
    ]),
    catchError(() => [
      PoolCommentsAction.failure(POOL_COMMENTS.UPDATE_ITEM),
    ]),
  )),
);

export const updateCommentPoolSuccessEpic = (action$) => action$.pipe(
  ofType(PoolCommentsAction.success(POOL_COMMENTS.UPDATE_ITEM).type),
  tap(() => toast.success('Updated successfully')),
  ignoreElements(),
);

export const updateCommentPoolFailureEpic = (action$) => action$.pipe(
  ofType(PoolCommentsAction.failure(POOL_COMMENTS.UPDATE_ITEM).type),
  tap(() => toast.error('Failure while updating')),
  ignoreElements(),
);

export const removeCommentPoolEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(PoolCommentsAction.request(POOL_COMMENTS.REMOVE_ITEM).type),
  mergeMap((action) => Services.poolComments.deleteComment(action.payload).pipe(
    map(() => [
      PoolCommentsAction.success(POOL_COMMENTS.REMOVE_ITEM, action.payload),
    ]),
    catchError(() => [
      PoolCommentsAction.failure(POOL_COMMENTS.REMOVE_ITEM),
    ]),
  )),
);

export const removeCommentPoolSuccessEpic = (action$) => action$.pipe(
  ofType(
    PoolCommentsAction.success(POOL_COMMENTS.REMOVE_ITEM).type,
    PoolCommentsAction.success(POOL_COMMENTS.REMOVE_COMMENT).type,
  ),
  tap(() => toast.success('Deleted successfully')),
  ignoreElements(),
);

export const removeCommentPoolFailureEpic = (action$) => action$.pipe(
  ofType(
    PoolCommentsAction.failure(POOL_COMMENTS.REMOVE_ITEM).type,
    PoolCommentsAction.failure(POOL_COMMENTS.REMOVE_COMMENT).type,
  ),
  tap(() => toast.error('Failure while deleting')),
  ignoreElements(),
);

export const fetchCollectionRequestEpic = (action$, state$, { Services }) => action$.pipe(
  ofType(PoolCommentsAction.request(POOL_COMMENTS.FETCH_COLLECTION).type),
  switchMap((action) => Services.poolComments.getComments(action.payload).pipe(
    map((comments) => [
      PoolCommentsAction.action(POOL_COMMENTS.SET_COLLECTION, comments),
    ]),
    catchError((error) => [
      PoolCommentsAction.failure(POOL_COMMENTS.FETCH_COLLECTION, error),
    ]),
  )),
);

export const setPoolPoolCommentsEpic = (action$) => action$.pipe(
  ofType(PoolCommentsAction.action(POOL_COMMENTS.SET_POOL).type),
  filter((action) => !isNull(action.payload)),
  map((action) => [
    PoolsActions.setPool(action.payload),
    PoolCommentsAction.request(POOL_COMMENTS.FETCH_COLLECTION, action.payload),
  ]),
);

export const poolCommentsEpic = combineEpics(
  enteredPoolCommentsEpic,
  // fetchCollectionRequestEpic,
  setPoolPoolCommentsEpic,
  addCommentPoolEpic,
  addCommentPoolSuccessEpic,
  addCommentPoolFailureEpic,
  updateCommentPoolEpic,
  updateCommentPoolSuccessEpic,
  updateCommentPoolFailureEpic,
  removeCommentPoolEpic,
  removeCommentPoolSuccessEpic,
  removeCommentPoolFailureEpic,
);
